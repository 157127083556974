<template>
    <el-form ref="clientInfo" class="card-tail" label-position="top" v-loading="$waiting.is('loading')">
        <div class="p-5 flex" style="width: 800px">
            <ModalCoverSheet :visible="modalCoverSheetVisible" :client-id="clientId" :client-info="clientInfo" :cover-sheet-details="coverSheetDetails" @close="modalCoverSheetVisible = false" />
            <div class="flex flex-col w-1/2 mr-11 space-y-2">
                <header class="text-header-blue text-xl font-semibold mb-3">Uppgifter till försättsbladet</header>
                <el-form-item>
                    <el-checkbox v-model="coverSheetDetails.addTableOfContents" @input="inputSaveCoverSheet(1)">Använd innehållsförteckning på försättsbladet</el-checkbox>
                </el-form-item>
            </div>
        </div>
        <div v-if="coverSheetDetails.addTableOfContents" class="p-5">
            <div class="flex flex-row">
                <el-table :data="tableOfContents">
                    <el-table-column label="Innehållsförteckning" width="250">
                        <template slot-scope="{ row }">
                            <el-input v-model="row.title" @input="inputSaveCoverSheet(2)" />
                        </template>
                    </el-table-column>
                    <el-table-column label="Sidnummer" width="120">
                        <template slot-scope="{ row }">
                            <el-input v-model="row.pageNumber" @input="inputSaveCoverSheet(3)" />
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="p-3">
                <el-button size="mini" type="warning" @click="addRowToTableofcontent">Lägg till</el-button>
                <el-button size="mini" type="primary" @click="deleteRowToTableofcontent">Ta bort</el-button>
            </div>
        </div>
        <div class="p-5 flex justify-end w-full">
            <el-button size="medium" type="primary" @click="goToPageBefore">Tillbaka</el-button>
            <el-button size="medium" type="warning">PDF Utkast Årsredovisning</el-button>
            <el-button size="medium" class="button is-info" @click="showInspectCoverSheet">Granska</el-button>
            <el-button size="medium" type="primary" @click="goToNextPage">Nästa</el-button>
        </div>
    </el-form>
</template>

<script>
import Api from "../annualReport.api";

export default {
    props: {
        annualReportId: {
            type: String,
            required: true,
        },
    },
    components: {
        ModalCoverSheet: () => import("../coverSheet/CoverSheetInspectModal.vue"),
    },
    data() {
        return {
            clientId: null,
            clientInfo: {},
            modalCoverSheet: false,
            modalCoverSheetVisible: false,
            coverSheetDetails: {},
            tableOfContents: [{ title: "", pageNumber: "2" }],
            timerSaveCoverSheetInfo: {
                1: null,
                2: null,
                3: null,
            },
        };
    },
    async created() {
        this.clientId = this.$store.state.topBar.selectedClient.value;
        this.fetchClientCompanyDetails();
        this.fetchCoverSheetDetails();
    },
    methods: {
        async fetchClientCompanyDetails() {
            this.$waiting.start("loading");
            // this.clientInfo = await Api.getClientCompanyDetails(this.clientId, this.annualReportId);
            this.companyInfo = await Api.getCompanyInfo(this.clientId, this.annualReportId);
            this.clientInfo = this.companyInfo.clientCompanyDetails;
            this.$waiting.end("loading");
        },
        async fetchCoverSheetDetails() {
            const responseCoverSheet = await Api.getCoverSheetDetails(this.annualReportId);
            this.coverSheetDetails = responseCoverSheet;
            if (responseCoverSheet.tableOfContents != null) {
                this.tableOfContents = responseCoverSheet.tableOfContents;
            }
            console.log("tableOfContents", this.tableOfContents);
        },
        async goToNextPage() {
            await this.$router.push(`/annualReportSubmenu/managementReport/${this.annualReportId}`);
        },
        async goToPageBefore() {
            await this.$router.push(`/annualReportSubmenu/companyInformation/${this.annualReportId}`);
        },
        showInspectCoverSheet() {
            this.modalCoverSheetVisible = true;
        },
        async inputSaveCoverSheet(inputNumber) {
            clearTimeout(this.timerSaveCoverSheetInfo[inputNumber]);
            this.timerSaveCoverSheetInfo[inputNumber] = setTimeout(async () => {
                await Api.updateCoverSheetDetails(this.annualReportId, this.coverSheetDetails);
                console.log("SAVED", this.coverSheetDetails);
            }, 2000);
        },
        addRowToTableofcontent() {
            const newObj = {
                title: "",
                pageNumber: "",
            };
            this.tableOfContents.push(newObj);
        },
        deleteRowToTableofcontent() {
            if (this.tableOfContents.length > 0) {
                this.tableOfContents.pop();
            }
        },
    },
};
</script>
